function eventMessageListener(event) {
    if (event.data.action === 'BANNER_SHOW') {
        const heightValue = event.data.data.height;
        const targetElement = document.getElementById('promotion-banner-slider-wrapper');
        if (targetElement) {
            targetElement.style.paddingTop = `${heightValue}px`;
            targetElement.style.marginTop = `-${heightValue}px`;
        } 
    }
}

window.addEventListener('message', function (event) {
    eventMessageListener(event);
});

module.exports = { eventMessageListener }